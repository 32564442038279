@import "../../../variables.scss";

.embeded-modal {
  .embeded-ui-modal {
    .modal-content {
      position: fixed;
      display: block;
      width: 670px;
      right: 0;
      top: 5%;

      height: 90vh;
      .modal-body {
        height: 96%;
        overflow-y: scroll;
        .accordion-title {
          margin-top: 10px;
          color: $accordion-color;
          font-weight: bold;
        }
        h5 {
          font-weight: bold;
        }
        .accordion-search-form {
          background: $white-color !important;
          border: 1px solid rgba(44, 43, 43, 0.1);
          border-radius: 4px;
          padding: 5px;
          margin-top: 3em;
          width: 40%;
          color: $accordion-search-color !important;
        }
      }
      .accordions {
        .accordion {
          background: $accordion-background;
          border-radius: 6px;
          padding: 10px;
          margin: 10px 0px;
          color: $accordion-search-color;
          .hide {
            height: 0px;
            overflow: hidden;
            border: none;
            .content {
              border: none;
            }
          }
          .show {
            height: 100%;
            overflow: none;
          }
          h6 {
            font-weight: 500;
            margin-left: 10px;
            cursor: pointer;
          }
          .content {
            margin: 10px;
            border-top: 1px solid lightgray;
          }
        }
      }
    }
  }
}
