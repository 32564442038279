@import "../../variables.scss";
#datastores,
#issues {
  .accounts-dropdown-font-style {
    color: $sub-heading-color;
    font-size: 12px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: $manrope-regular-font !important;
  }
  .accounts-dropdown__value-container {
    height: 31px;
  }
  .accounts-dropdown__single-value {
    padding: 2px 8px;
    @extend .accounts-dropdown-font-style;
  }
  .accounts-dropdown__control {
    height: 31px;
    min-height: 31px;
    width: 150px;
  }
  .accounts-dropdown__indicator-separator {
    margin: 0px;
    display: none;
  }

  .accounts-dropdown__indicator {
    padding: 0px 8px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
  .accounts-dropdown__menu {
    @extend .accounts-dropdown-font-style;
  }

  .accounts-dropdown__input-container {
    color: $sub-heading-color !important;
    height: 28px;
    margin: 0px;
    padding: 0px;
  }
  .accounts-dropdown__placeholder {
    padding: 2px 8px;
    @extend .accounts-dropdown-font-style;
  }

  .accounts-dropdown__input {
    border-radius: 3px;
    border: solid 1px $card-border-color;
    background-color: $white-color;
    width: 150px;
    height: 31px !important;
    @extend .accounts-dropdown-font-style;
  }
  .toggle-label {
    color: $sub-heading-color;
    vertical-align: text-top;
  }
  .risk-accept-toggle {
    vertical-align: middle;
    .react-toggle-track {
      background: $toggle-track;
      height: 22px;
    }
    .react-toggle-thumb {
      background-color: $toggle-button-color;
      border: none;
      box-shadow: none;
    }
  }
  .asset-id-tag {
    border: 1px solid $score-low-color;
    vertical-align: text-top;
    padding: 0.3rem;
    border-radius: 10px;
    img {
      margin-bottom: 4px;
      cursor: pointer;
    }
  }
  .risk-accept-toggle.react-toggle--checked {
    .react-toggle-track {
      background: $toggle-track;
    }
    .react-toggle-thumb {
      background-color: $toggle-button-color;
      border: none;
      box-shadow: none;
    }
  }
}
