@import "../../../variables.scss";

#top-panel-main-container {
  background-color: $card-background-color;
  border-radius: 5px;
  .disable-button {
    opacity: 0.3;
  }
  .workday-block {
    background: rgba(8, 12, 32, 0.6);
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: none;
    margin: 10px;
    min-height: 310px;
    padding-top: 1.5em;
    .count-circle {
      width: 62px;
      height: 62px;
      background-color: #c83a39;
      border-radius: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      color: $white-color;
      font-weight: 600;
      margin-top: 0.7rem;
    }
    .workday-heading {
      font-family: $manrope-bold-font;
      font-weight: 600;
      font-style: normal;
      font-size: 1rem;
      color: $sub-heading-color;
      text-align: left;
    }
    .workday-subheading {
      font-family: $manrope-bold-font;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: $manatee-shade1;
      text-align: left;
    }
    .workday-subheading-value {
      font-family: $manrope-regular-font;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: $subtext-color;
      text-align: left;
    }
    .subvalue-bold {
      font-family: $manrope-regular-font;
      font-weight: 650;
      font-style: normal;
      font-size: 0.875rem;
      color: $sub-heading-color;
      text-align: left;
    }
  }
  .sensitive-data-block {
    padding-top: 1.5em !important;
    min-height: 232px;
    // overflow-y: scroll;

    .sensitive-data-heading {
      font-family: $manrope-bold-font;
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      color: $sub-heading-color;
      text-align: left;
    }
    .sensitive-data-subheading {
      font-family: $manrope-regular-font;
      font-style: normal;
      font-size: 16px;
      text-align: left;
      span {
        font-family: $manrope-bold-font;
        font-weight: 600;
        text-decoration: underline;
        color: $sub-heading-color;
      }
    }
    .title-style {
      font-size: 12px;
    }
    .vulns-data {
      font-size: 12px;
      overflow-y: scroll;
      max-height: 158px;
    }
    .sensitive-data-record {
      .sensitive-data-title {
        background-color: #ffffff00;
        font-family: $manrope-bold-font;
        font-weight: 600;
        font-style: normal;
        font-size: 14px;
        color: $subtext-color;
        text-align: left;
      }
    }
  }
  .risk-title {
    &-accept {
      color: $risk-title-accept;
    }
    &-accepted {
      color: $success-color;
    }
  }
}

.text-decoration-underline {
  text-decoration: underline;
}

.font-color-glaucous {
  color: $sub-heading-color !important;
}
.card-text {
  font-size: 14px;
}
.card-subtext {
  font-size: 12px;
}
.card-tabs {
  border-width: 0px;
  left: 0px;
  top: 0px;
  color: $white-color;
  padding-right: 2px;
  padding-left: 2px;
  background: inherit;
  background-color: $sub-heading-color;
  border: none;
  border-radius: 16px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.red-dot {
  font-size: 13px;
}
