@import "../../../variables.scss";

#bottom-panel-main-container {
  padding: 10px;
  padding-top: 1.5em;
  // height: 100%;
  background: $card-background-color;

  .left-panel {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(211, 211, 211, 1);
    border-radius: 5px;
    p {
      font-size: 12px;
    }
  }
  .container {
    width: 100%;
  }
  .nav-tabs {
    margin-bottom: 4px;

    .disabled-tab {
      opacity: 0.5;
    }
  }
  .attack-path-table {
    width: 100%;
    max-height: 325px;
    overflow-y: auto;
    .table {
      tr {
        background: $midnight-express-1;
        margin-bottom: 4px;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
          background: $violent-violet;
        }
      }
    }
    .active-path-row {
      background: $violent-violet !important;
    }
  }

  div table tbody tr td {
    &:first-child {
      border-left: 1px solid $card-border-color;
    }
    &:last-child {
      border-right: 1px solid $card-border-color;
    }
  }
  @media (max-width: 1199px) {
    border-bottom: 1px solid $card-border-color;
  }
}
.left-panel-title {
  font-weight: 600;
  color: #c83939;
  font-size: 12px;
}
.left-panel-subtitle {
  font-size: 12px;
  color: $subtext-color;
  opacity: 0.5;
}

.left-panel-icon {
  width: 20px;
  background-color: red;
}
.incognito-icon {
  font-size: 25px;
}
.vuls-row {
  border-radius: 2px !important;
  height: 42px;
  padding: 10px !important;
  background: rgba(255, 255, 255, 0.03);
}

.leaplabs-low {
  border-left: 5px solid $score-low-color !important;
  padding: 0px !important;
}

.leaplabs-moderate {
  border-left: 5px solid $score-moderate-color !important;
  padding: 0px !important;
}

.leaplabs-high {
  border-left: 5px solid $score-high-color !important;
  padding: 0px !important;
}

.leaplabs-elevated {
  border-left: 5px solid $score-elevated-color !important;
  padding: 0px !important;
}
table {
  border-collapse: separate;
  border-spacing: 0 5px;
}
tr {
  background-color: rgba(0, 0, 0, 0.0196078431372549);
  cursor: pointer;

  td {
    height: auto;
    text-align: left;
    padding: 5px;
    &:first-child {
      border-bottom-left-radius: 2px;
    }
    &:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
}
