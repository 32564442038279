@import "../../../variables.scss";


.nodata-container {
    min-height: 60vh;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20vh;
  }
  h4 {
    color: $no-data-heading;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
  }
}
