@import "../../variables.scss";

.datastore {
  &-card {
    border: 1px solid rgba(211, 211, 211, 1);
    cursor: pointer;
    background-color: $card-background-color;
    &-icon {
      float: right;
    }
    &-text {
      font-size: 48px;
    }
    &-title {
      font-size: 14px;
    }
  }
}
.table-card {
  .table-responsive {
    .table {
      background-color: $card-background-color;
      border-collapse: separate;
      border-spacing: 0 7px;

      &-card {
        padding: 0 25px 10px 25px;
        background-color: $card-background-color;
      }
      &-data {
        font-family: $manrope-regular-font;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: $subtext-color !important;
      }
      &-type {
        color: $subtext-color !important;
      }
      tbody {
        tr {
          &:hover {
            .row-id {
              background-color: $error-color;
              color: $white-color;
            }
            > * {
              --bs-table-accent-bg: inherit;
            }
          }
        }

        .Medium {
          &:hover {
            .row-id {
              background-color: rgb(249, 160, 76);
              color: $white-color;
            }
          }
        }

        .Low {
          &:hover {
            .row-id {
              background-color: rgb(9, 171, 235);
              color: $white-color;
            }
          }
        }
      }

      thead {
        th {
          border: hidden;
          text-align: left;
          // background-color: $card-background-color;
          position: relative;
          padding-right: 15px;
        }
        tr {
          color: $subtext-color;
        }
      }
      tr {
        cursor: pointer;
        td {
          height: auto;
          text-align: left;
          padding: 5px;

          &:first-child {
            border-bottom-left-radius: 10px;
          }
          &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }

    .table-no-background {
      background-color: $cyprus !important;
    }
  }
}

.row-id {
  background-color: rgba(200, 57, 57, 0.6980392156862745);
  color: $white-color !important;
  text-align: center !important;
  font-size: 16px !important;
}

.URGENT-RISK,
.NEW-RISK {
  background-color: rgba(200, 57, 57, 0.6980392156862745) !important;
  color: $white-color !important;
  text-align: center !important;
  font-size: 16px !important;
}

.IMPORTANT-RISK {
  background-color: rgba(242, 172, 10, 0.698) !important;
  color: $white-color !important;
  text-align: center !important;
  font-size: 16px !important;
}

.NEW-DATASTORES {
  background-color: rgba(143, 187, 240, 0.698) !important;
  color: $white-color !important;
  text-align: center !important;
  font-size: 16px !important;
}

.head {
  border-top: hidden;
}

.pagination {
  &-btn {
    color: $white-color;
    border: none;
    color: $white-color;
    padding: 5px 5px;
    font-size: 14px;
    margin: 5px;
    cursor: pointer;
    border-radius: 50%;

    &-disable {
      @extend .pagination-btn;
      cursor: not-allowed;
    }
    &-icon {
      margin-top: 10px;
    }
  }
}

.page-heading {
  font-size: 1rem;
  font-family: $manrope-bold-font;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  color: $white-color;
  span {
    font-family: $manrope-bold-font;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: $toggle-button-color;
    text-align: left;
  }
}
.dropdown-title {
  float: left;
  line-height: 1;
}
.dropdown-toggle::after {
  float: right;
  margin: 7px 0 7px;
}
.table-main {
  background: $card-background-color;
  min-height: 60vh;
  padding: 2em 0.5em;
}
.border-bottom {
  border-bottom: 3px solid $card-border-color !important;
}
.card-main {
  margin: 0 10px 0 10px;
}

.data-store-name {
  color: $text-primary !important;
  font-weight: 400 !important;
  font-style: normal;
  b {
    color: $white-color;
  }
}
.table-dropdown {
  width: 140px;
  height: 31px;
  background: inherit;
  background-color: $card-background-color;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(211, 211, 211, 1);
  border-radius: 5px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
