@import "../../../variables.scss";

.risk-card-container {
  .card {
    height: 100%;
    border-image: $border-line;
    border-top-style: solid;
    border-top-width: 2px;
  }
  .main-card {
    min-width: 100px;
  }
  .risk-card-title {
    font-family: $manrope-regular-font;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white-color;
    border-bottom: 0.559702px solid $modal-header-border;
    padding: 10px 0 16px;
  }
  .vertical-line {
    margin-left: 15px;
    border-left: 1px solid $card-border-color-3;
    height: 105px;
    background-color: $body-background-color;
  }

  .risk-card-score {
    font-family: $manrope-regular-font;
    font-size: 36px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 10px 0 5px;
  }

  .score-direction {
    float: right;
    font-size: 18px;
  }
  .low-opacity {
    opacity: 0.4;
  }
  .steps-count {
    width: 23px;
    height: 21px;
    margin-right: 8px;
    line-height: 23px;
    border-radius: 50%;
    text-align: center;
    object-fit: contain;
    &-disable {
      @extend .steps-count;
      opacity: 1;
    }
    &-enable {
      @extend .steps-count;
      opacity: 1;
    }
  }

  .card-icon {
    width: 16px;
    background: unset;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    margin-top: 8px;
  }
  .card-loading-icon {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    margin-top: 8px;
  }
  .card {
    width: 100%;
    &-disable {
      opacity: 1;
    }
    &-enable {
      border: solid 1px $grey-color !important;
      background-color: $white-color;
    }
  }
  .discovering-text {
    padding-right: 0px;
    padding-left: 15px;
    border-left: 1px solid $card-border-color-3;
    height: 100%;
    @media (max-width: 992px) {
      border: 0;
    }
    img {
      width: 22px;
    }
    div {
      padding-left: 15px;
      p {
        font-family: $manrope-regular-font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $subtext-color;
        margin-bottom: 0.5rem;
      }
      button {
        background-color: $score-low-color;
      }
    }
  }
}
