.integration-cards {
  .card-item{
    :not(:last-child){
      padding-right: 0;
    }
  }
  .card {
    .card-body {
      img {
        height: 30px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }
    }
  }
}
