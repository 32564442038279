@import "../../variables.scss";

.datastore-details-issues {
  .accounts-dropdown__single-value {
    color: #878993 !important;
  }
  // .accounts-dropdown__indicators {
  //   display: none;
  // }
  .accounts-dropdown {
    .accounts-dropdown__control {
      min-width: 140px;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
    }
    .accounts-dropdown__control--is-focused {
      outline: none;
    }
    .accounts-dropdown__single-value {
      font-size: 14px;
      color: $spun-pearl;
    }
    .accounts-dropdown__value-container {
      padding: 2px 6px;
    }

    .accounts-dropdown__indicator-separator {
      display: none !important;
    }
    .accounts-dropdown__menu {
      background: $midnight-express;
      border: 1px solid $midnight-express-blue;
      .accounts-dropdown__menu-list {
        width: 100%;
        background: $midnight-express;
        .accounts-dropdown__option {
          margin-top: 0.125rem;
          margin-bottom: 0.125rem;
          &:hover {
            background: $violent-violet;
            color: $toggle-button-color;
          }
        }
        .accounts-dropdown__option {
          background: none;
          font-size: 14px;
          color: $spun-pearl;
        }
        .accounts-dropdown__option--is-selected {
          background: $violent-violet;
          color: $toggle-button-color;
        }
      }
    }
  }
}
.datastore-issues {
  padding-top: 1.5em;
  .body-title {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $sub-heading-color;
    padding-top: 2px;
  }
  .nav-pills .nav-item a {
    position: relative;
    .hacker-icon {
      position: absolute;
      right: 0px;
      top: 4px;
    }
    color: $grey-shade;
    border-bottom: $input-background 1px solid;
  }
  .nav-pills li a.active {
    color: $white-color;
    background-color: unset;
    border-bottom: 1px solid $toggle-button-color;
  }
  .nav-pills .nav-link {
    border-radius: unset;
    border-bottom: 1px solid;
  }
  .nav-link {
    font-size: 14px;
  }
}
