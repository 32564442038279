@import "../../../variables.scss";
#add-policy-modal {
  font-family: $manrope-regular-font;
  .modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    .modal-dialog {
      height: 200px;
      max-width: 750px;
      .modal-content {
        height: 100% !important;
        background: $card-background-color;

        .modal-header {
          padding: 15px 15px;
          .header-title {
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $sub-heading-color;
          }

          .delete-icon {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
        }
        .modal-body {
          .policy-separator {
            border: 0.1px solid $grey-color;
          }
          .modal-loading-img {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            margin-top: 50px;
            text-align: center;
            z-index: 1;
            width: 200px;
          }
          .my-custom-react-select__menu {
            z-index: 2;
          }
          .risk-accept-toggle-left {
            margin-right: 44px;
          }
          border-bottom: 1px solid;
          border-bottom-color: $card-border-color;
          max-height: 460px;
          overflow: scroll;
          .modal-subtitle {
            color: #374948;
            font-size: 14px;
            font-weight: 500;
          }
          .form-control {
            line-height: 45px;
            background: #e9edf4;
            border: 0px solid #d7dbda;
            font-size: 14px;
            color: #777d74;
          }
          .modal-textarea {
            border-width: 0px;
            left: 0px;
            top: 0px;
            width: 100%;
            background: inherit;
            background-color: $input-color;
            box-sizing: border-box;

            border-radius: 2px !important;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }
        .modal-footer {
          .modal-button-cancel {
            background-color: $card-heading-color;
            color: $white-color;
          }
        }
      }
    }
  }
}

.risk-accept-toggle {
  vertical-align: middle;
  .react-toggle-track {
    background: $toggle-track;
    height: 22px;
  }
  .react-toggle-thumb {
    background-color: $toggle-button-color;
    border: none;
    box-shadow: none;
  }
}
.risk-accept-toggle.react-toggle--checked {
  .react-toggle-track {
    background: $toggle-track;
  }
  .react-toggle-thumb {
    background-color: $toggle-button-color;
    border: none;
    box-shadow: none;
  }
}

.policy-chip {
  margin: 10px;
  background: #1d2032;
  color: #3d61ff;
  border: 1px solid #24356e;
  padding: 0px 10px;
  border-radius: 3px;
  img {
    cursor: pointer;
  }
}

.checkbox-container {
  .new {
    padding: 10px;
  }

  .form-group {
    display: block;
    margin-bottom: 15px;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    color: #b7b8be;
    margin-left: 10px;
    font-size: 14px;
  }

  .form-group label:before {
    content: "";
    background-color: transparent;
    border: 2px solid #575a68;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 5px;
  }

  .form-group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 5px;
    height: 12px;
    border: solid #ae69f4;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
