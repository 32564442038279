@import "../../../variables.scss";

#bottom-panel-main-container {
  background: $card-background-color;
  div {
    table {
      tbody {
        .active-row {
          border: 2px solid $sub-heading-color;
          // box-shadow: 0 0 3px blue !important;
        }
      }
    }
  }
  .left-panel {
    background-color: $card-background-color;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(211, 211, 211, 1);
    border-radius: 5px;
    p {
      font-size: 12px;
    }
  }
  div table tbody tr:hover td {
    &:first-child {
      border-left: 1px solid $sub-heading-color;
    }
    &:last-child {
      border-right: 1px solid $sub-heading-color;
    }
  }
  div table tbody tr td {
    &:first-child {
      border-left: 1px solid $card-border-color;
    }
    &:last-child {
      border-right: 1px solid $card-border-color;
    }
  }
}
.left-panel-title {
  font-weight: 600;
  color: #c83939;
  font-size: 12px;
}
.left-panel-subtitle {
  font-size: 12px;
  color: $subtext-color;
  opacity: 0.5;
}
#top-panel-main-container {
  .row {
    table {
      tr {
        td {
          height: auto;
        }
      }
    }
  }
}
.left-panel-icon {
  width: 20px;
}
.numberCircle {
  width: 30px;
  height: 30px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  background-color: $error-color;
  color: $white-color;
  display: flex;
  justify-content: center;
  align-items: center;
}
