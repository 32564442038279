@import "../../../variables.scss";
.popup-content {
  width: auto !important;
  padding: 0px !important;
  font-size: 12px;
  max-width: 200px;
}

.node-image {
  border: none;
  // &:hover {
  //   border: solid 2px $sub-heading-color;
  //   border-radius: 50%;
  // }

  // &:active {
  //   border: solid 2px $sub-heading-color;
  //   border-radius: 50%;
  // }
}

@mixin node-score-ring($color) {
  border: 3px solid $color;
  border-radius: 50%;
  padding: 0.5px;
}
.link {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash-animate 50s linear forwards;
  animation-iteration-count: infinite;
}

@keyframes dash-animate {
  100% {
    stroke-dashoffset: 0;
  }
}

.node-score-low {
  @include node-score-ring($score-low-color);
}

.node-score-moderate {
  @include node-score-ring($score-moderate-color);
}

.node-score-high {
  @include node-score-ring($score-high-color);
}

.node-score-elevated {
  @include node-score-ring($score-elevated-color);
}

.attack-image-primary {
  position: absolute;
  top: 0;
  width: 16px;
  right: 42px;
  margin-top: 22px;
}

.attack-image {
  position: absolute;
  top: 0;
  width: 16px;
  right: 45px;
  margin-top: 25px;
}

.eks-image-primary {
  position: absolute;
  width: 16px;
  right: 42px;
  bottom: 25px;
}

.eks-image {
  position: absolute;
  width: 16px;
  right: 45px;
  bottom: 25px;
}

.tooltip-style {
  .header {
    background-color: #ededed;
    padding: 5px;
    border-bottom: 1px solid #d3d3d3;
    font-weight: 600;
  }
  .tooltip-content {
    padding: 2px;

    .card-header {
      padding: 2px;
      font-weight: 600;
    }
    .card-body {
      padding: 5px 10px;
    }
  }
}

.right-panel {
  position: absolute;
  background-color: $white-color;
  border-radius: 4px;
  box-shadow: -5px 0 5px -1px rgba(0, 0, 0, 0.06);
  right: 16px;
  top: 1px;
  height: 100%;
  width: 200px;

  .security-issue-button {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-bottom: 5px;
    button {
      border-radius: 5px;
      background-color: $score-low-color;
    }
  }

  .header {
    font-family: $manrope-regular-font;
    background-color: $sub-heading-color;
    padding: 10px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white-color;

    .close-icon {
      width: 20px;
      height: 20px;
      float: right;
      object-fit: contain;
      opacity: 0.9;
      margin-top: -3px;
      cursor: pointer;
    }
  }
  .contents {
    .content-header {
      font-family: $manrope-regular-font;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $sub-heading-color;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 4px;
      margin-top: 5px;
    }
    .content-body {
      font-family: $manrope-regular-font;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #919191;
      padding-left: 10px;
      padding-right: 10px;
      word-break: break-all;
    }

    hr {
      margin-top: 7px;
      margin-bottom: 7px;
    }
  }
}
