@import "../../../variables.scss";
.scatter-plot-container {
  .medium-tooltip {
    width: 200px;
    height: 30px;
    background: black;
    position: absolute;
    left: 71px;
    margin-top: 30px;
    border-radius: 5px;
  }

  .high-tooltip {
    width: 50px;
    height: 30px;
    background: #eee;
    position: absolute;
    right: 14px;
    margin-top: 30px;
    border-radius: 5px;
  }

  .low-tooltip {
    width: 50px;
    height: 30px;
    background: #eee;
    position: absolute;
    left: 71px;
    margin-top: 433px;
    border-radius: 5px;
  }
  .toolbar-risk {
    width: 20px;
    background-color: $error-color;
  }
}
.scatter-plot-chart {
  .tick line {
    stroke: $chart-grid-line-color;
  }

  .tick text {
    fill: $chart-text-color;
  }

  path.domain {
    stroke: none;
  }
  .axis-lable {
    font-size: 16px;
    fill: $chart-text-color;
  }
  .circle {
    width: 20px;
  }
  #scatter-plot {
    margin-top: 10px;
  }
  #scatter-plot > g {
    g:nth-child(4) > path {
      z-index: -1;
    }
    g:nth-child(8) > g > text {
      font-size: 14px;
      font-weight: bold;
    }
    g:nth-child(7) > g > text {
      font-size: 14px;
      font-weight: bold;
    }
    text:nth-child(10) {
      fill: $chart-quarter-color;
      font-size: 14px;
      font-weight: bold;
      font-family: $manrope-regular-font;
    }
    text:nth-child(9) {
      fill: $chart-quarter-color;
      font-size: 14px;
      font-weight: bold;
      font-family: $manrope-regular-font;
    }
  }
}

#impacteddatastores
  > div
  > svg
  > g
  > g:nth-child(2)
  > g:nth-child(1)
  > g
  > g
  > g
  > g
  > g
  > g:nth-child(3)
  > g
  > g
  > g
  > g:nth-child(3) {
  color: white;
}
