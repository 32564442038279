@import "../../variables.scss";
#home-layout {
  .nav-pills .nav-item a {
    position: relative;
    .hacker-icon {
      position: absolute;
      right: 0px;
      top: 4px;
    }
    color: $grey-shade;
    border-bottom: $input-background 1px solid;
  }
  .nav-pills li a.active {
    color: $white-color;
    background-color: unset;
    border-bottom: 1px solid $toggle-button-color;
  }
  .nav-pills .nav-link {
    border-radius: unset;
    border-bottom: 1px solid;
  }
  .nav-link {
    font-size: 14px;
  }

  .view-all {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: $toggle-button-color;
    cursor: pointer;
  }
  .sub-title {
    color: $spun-pearl;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  #datastores-layout {
    .empty-column {
      width: 72px !important;
    }
  }
  #dataflows-layout {
    table th:first-child {
      border-radius: 2px 0 0 2px;
    }

    table th:last-child {
      border-radius: 0 2px 2px 0;
    }
    tbody tr,
    thead tr {
      display: grid;
      grid-template-columns: 1fr 1fr 130px 40px; // or whatever division you wanna do
      grid-auto-columns: 1fr;
    }

    tbody {
      height: 250px; // or whatever height
      width: 100%;
      overflow-y: auto;
      display: block;
    }
    .table {
      background-color: unset !important;
      border-collapse: collapse !important;
      .hacker-icon-risk {
        position: absolute;
        left: 2px;
      }
      thead {
        tr {
          th {
            padding: 10px 8px;
            text-align: start;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      tbody {
        tr {
          background: $midnight-express-1;
          margin-bottom: 4px;
          border-radius: 2px;
          td {
            text-align: start;
            padding: 8px;
            border: unset;
            height: 40px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
          }
          td:last-child {
            width: fit-content;
          }
          &:hover {
            background: $violent-violet;
          }
        }
      }
    }
  }
  #top-issues > div > table > thead > tr > th:nth-child(3) {
    padding-left: 3px;
  }
  #top-issues > div > table > thead > tr > th:nth-child(4) {
    padding-left: 3px;
  }
  #securityissues-layout {
    .red-dot {
      margin-right: 10px;
      height: 7px;
      width: 7px;
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 2px;
    }
    table th:first-child {
      padding-left: 28px !important;
      border-radius: 2px 0 0 2px;
    }

    table th:last-child {
      border-radius: 0 2px 2px 0;
    }

    tbody {
      height: 255px; // or whatever height
      width: 100%;
      overflow-y: unset;
      display: block;
    }
    .table {
      background-color: unset !important;
      border-collapse: collapse !important;
      thead {
        tr {
          background-color: $midnight-express-1;
          border-radius: 2px;
          th {
            padding: 10px 8px;
            text-align: start;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            border: unset;
          }
        }
      }

      tbody {
        tr {
          background: $midnight-express-1;
          margin-bottom: 4px;
          border-radius: 2px;

          .datastore-clicked {
            font-size: 16px;
            color: $dark-blue;
            font-weight: 700;
          }
          td {
            text-align: start;
            padding: 8px;
            border: unset;
            height: 40px;
            color: $table-text;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
          }
          td:last-child {
            justify-content: center;
          }
          &:hover {
            background: $violent-violet;
          }
        }
      }
    }
    .top-issue-types {
      tbody tr,
      thead tr {
        display: grid;
        grid-template-columns: 1fr 1fr 120px 90px; // or whatever division you wanna do
        grid-auto-columns: 1fr;
      }
    }
    .top-issues {
      tbody tr,
      thead tr {
        display: grid;
        grid-template-columns: 130px 1fr 1fr 90px;
        grid-auto-columns: 1fr;
      }
    }
  }
  #achievements-layout {
    .achievement-card {
      height: 130px;
      padding: 20px;
      border-radius: 5.60172px;
      background-color: $achievement-card-color;
    }
    .achievement-value {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: $table-text;
    }
    .achievement-title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $table-text;
    }
  }
}
.bar-popup-content {
  background-color: $tangaroa;
  max-width: unset;
  margin-left: -1.563rem;
  margin-top: 2.813rem;
  border: unset;
  .popup-body {
    width: 18.75rem;
    padding: 0.625rem;
    hr {
      background: $white-color;
      opacity: 0.1;
    }
    .title-1 {
      color: $table-text;

      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1rem;
    }
    .subtitle-1 {
      color: $manatee-shade1;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.063rem;
    }
    .subtitle-2 {
      color: $ghost-grey;
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.063rem;
    }
  }
  .title {
    color: $table-text;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
  }
}
#high-risk > div:nth-child(1) > div > h6 > span {
  color: $grey-shade;
}
