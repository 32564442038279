$color_1: #7083b7;
$color_6: #b86e13;
$background-color_2: #ebebeb;
$background-color_3: #d2d2d2;
$background-color_4: #a5c5e8;
$background-color_5: #fff;
$background-color_6: #9f1112;
$background-color_7: #81a7cd;
$background-color_8: #e0a44c;
$background-color_9: #b1c4d8;
$card-heading-color0: #9f111278;
$card-heading-color1: #e0a44c78;
$card-heading-color2: #81a7cd78;
$border-bottom-color_1: #9f1112;
$border-bottom-color_2: #e0a44c;
$border-bottom-color_3: #81a7cd;

@import "../../../../variables.scss";

/* Small */
/* Medium */
/* Large */
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
h2 {
  text-transform: uppercase;
  color: $color_1;
}
.datastore-container {
  background: #f5f5f5;
}
.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .right {
      display: flex;
      align-items: center;
      p {
        margin-right: 50px;
        font-size: 22px;
        font-weight: bold;
        color: $color_1;
      }
      i {
        color: $white-color;
        background-color: $card-heading-color;
        padding: 5px 10px;
        border-radius: 50%;
        opacity: 0.7;
        &:first-of-type {
          margin-right: 10px;
        }
      }
      i.active {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
.content {
  position: relative;

  .first-dashed {
    position: absolute;
    top: 80px;
    z-index: 2;
    width: 99%;
    border-top: 1px dashed rgb(198, 195, 195);
  }
  .issues-first-dashed {
    top: 85px !important;
  }
  .second-dashed {
    position: absolute;
    top: 330px;
    z-index: 2;
    width: 99%;
    border-top: 1px dashed rgb(198, 195, 195);
  }
  .bottom-panel {
    gap: 2px;
  }
  .bar-tooltip {
    background-color: red;
  }
  .popup-content {
    margin: auto;
    background-color: red;
    width: 50%;
    padding: 5px;
  }
  .info-top-panel {
    display: flex;
    gap: 1px;
    .colum-no-background {
      display: flex;
      // flex-direction: column;
      align-items: center;
      width: 45px !important;
      border-radius: 10px;
      position: relative;
      cursor: pointer;
      border: 2px solid transparent;
    }
  }
  .info-bottom-panel {
    display: flex;
    gap: 1px;
    .colum-no-background {
      display: flex;
      // flex-direction: column;
      align-items: center;
      width: 45px !important;
      border-radius: 10px;
      position: relative;
      cursor: pointer;
      border: 2px solid transparent;
    }
  }
  .info {
    display: flex;
    gap: 5px;

    .colum {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 61px;
      border-radius: 10px;
      position: relative;
      cursor: pointer;
      // border: 4px solid #f5f5f5;
      background-color: rgba(255, 255, 255, 0.05);
      &:hover {
        .head {
          // background-color: $background-color_4 !important;
          // opacity: 1 !important;
        }
      }
      // &::after {
      //   content: "";
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 20px;
      //   border-radius: 0 0 5px 5px;
      // }
      .attck {
        transform: translateY(145px);
        font-size: 10px;
        font-weight: bold;
        color: $error-color;
      }
      .number {
        transform: translateY(-22px);
        font-size: 10px;
        font-weight: bold;
        color: $card-heading-color;
      }
      .bar {
        height: 5px;
        width: 100%;
        border-radius: 6px 6px 0px 0px;
      }
      .head {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0px;
        // background-color: $background-color_9;
        border-bottom: 1px solid;
        opacity: 0.3;

        // border-radius: 10px 10px 0 0;
        opacity: 0.9;
        // margin-top: -18px;
        img {
          margin-bottom: -9px;
        }
        .bug-img {
          margin-bottom: -20px;
          width: 20px;
          margin-left: 1.2em;
          position: absolute;
          top: 2px;
        }
        .icon-img {
          // width: 30px;
          margin-top: 15px;
          margin-bottom: -6px;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
        span {
          text-align: center;
          padding-bottom: 11px;
          margin-top: 5px;
          font-size: 9px;
          font-weight: bold;
        }
        .bug-span {
          text-align: center;
          font-size: 10px;
          font-weight: bold;
          // margin-top: 25px;
          padding-bottom: 1px !important;
        }
        .icon-span {
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          text-transform: capitalize;
          color: $white-color;
        }
      }

      .datastore-head {
        height: 18%;
        position: relative;
        .hacker-icon {
          position: absolute;
          top: 3px;
          right: 1px;
          width: 17px;
        }
      }
      .issues-head {
        height: 18%;
      }
      .body {
        position: relative;
        width: 100%;
        display: flex;
        align-items: flex-end;
        .range {
          width: 100%;
          z-index: 2;
        }
        .number-top {
          z-index: 4;
          text-align: center;
          color: #c35e7a;
        }
        .number-bottom {
          z-index: 4;
          text-align: center;
          color: #cea983;
        }
        .r1 {
          height: 100px;
          bottom: -68px;
        }
        .r3 {
          height: 100px;
          bottom: -50px;
        }
        .r4 {
          height: 120px;
          bottom: -48px;
        }
        .r5 {
          height: 108px;
          bottom: -60px;
        }
        .r6 {
          height: 140px;
          bottom: -30px;
        }
        .r7 {
          height: 130px;
          bottom: -40px;
        }
        .r8 {
          height: 110px;
          bottom: -40px;
        }
        .r9 {
          height: 120px;
          bottom: -40px;
        }
        .r10 {
          height: 70px;
          bottom: -30px;
        }
      }
      .upper-body {
        height: 100px;
        border-bottom: 2px solid #5f5f5f;
      }
      .lower-body {
        height: 100px;
      }
      .b2 {
        .number-top {
          position: relative;
          font-size: 12px;
          margin-top: -23px;
          font-weight: 600;
        }
        .number-bottom {
          font-size: 12px;
          //   margin-bottom: 41px;
          font-weight: 600;
        }
        // .issues-number-bottom {
        //   margin-bottom: 24px !important;
        // }
      }
      .b3 {
        .number-top {
          top: -20px;
        }
        .number-bottom {
          bottom: -74px;
        }
      }
      .b4 {
        .number-top {
          top: 15px;
        }
        .number-bottom {
          bottom: 30px;
        }
      }
      .b5 {
        .number-top {
          top: 30px;
        }
        .number-bottom {
          bottom: -82px;
        }
      }
      .b6 {
        .number-top {
          top: -30px;
        }
        .number-bottom {
          bottom: -54px;
        }
      }
      .b7 {
        .number-top {
          top: -12px;
        }
        .number-bottom {
          bottom: -62px;
        }
      }
      .b8 {
        .number-top {
          top: 9px;
        }
        .number-bottom {
          bottom: -63px;
        }
      }
      .b9 {
        .number-top {
          top: -1px;
        }
        .number-bottom {
          bottom: -62px;
        }
      }
      .b10 {
        .number-top {
          top: 127px;
        }
        .number-bottom {
          bottom: 35px;
        }
      }
      .last-number {
        position: absolute;
        bottom: 0px;
        font-size: 15px !important;
        font-weight: 600;
        color: $subtext-color;
        z-index: 10000;
        font-size: 13px;
      }
      .last-number-active {
        position: absolute;
        bottom: -3px;
        font-size: 15px;
        font-weight: 600;
        color: $white-color;
        z-index: 10000;
        font-size: 13px;
      }
    }
    .colum-no-background {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 55px !important;
      border-radius: 10px;
      position: relative;
      cursor: pointer;
      border: 2px solid transparent;
    }
    .colum-high {
      background-color: $background-color_5;
      &::after {
        background-color: $background-color_6;
      }
      .head {
        background-color: $background-color_5;
        position: relative;
        border-bottom-color: $border-bottom-color_1;
        opacity: 1 !important;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $card-heading-color0;
          z-index: 1;
          border-radius: 5px 5px 0px 0px !important;
        }
      }
    }
    .colum-low {
      background-color: $background-color_5;
      &::after {
        background-color: $background-color_7;
      }
      .head {
        background-color: $background-color_5;
        position: relative;
        border-bottom-color: $border-bottom-color_3;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $card-heading-color2;
          z-index: 1;
          border-radius: 5px 5px 0px 0px !important;
        }
      }
    }
    .colum-medium {
      background-color: $background-color_5;
      &::after {
        background-color: $background-color_8;
      }
      .head {
        background-color: $background-color_5;
        position: relative;
        border-bottom-color: $border-bottom-color_2;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $card-heading-color1;
          z-index: 1;
          border-radius: 5px 5px 0px 0px !important;
        }
      }
    }
  }
  .main-colum {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    width: 120%;
    max-width: 72px;
  }
  .no-data-main-colum {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    width: 120%;
    max-width: 72px;
    height: 281px;
  }
  .datastores-main-colum {
    .head {
      height: 55px;
      border-radius: 5px 5px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.05);
      // border-bottom: 1px solid;
      // width: 65px;
    }
    .footer {
      height: 22px;
      padding: 5px 0 0 0px;
      background-color: $background-color_3;
      border-radius: 0 0 5px 5px;
    }
    h3 {
      color: #b7b8be;
      font-size: 10px;
      text-align: center;
      font-weight: 600;
    }
    .body {
      .title-1 {
        border-bottom: 1px solid $background-color_5;
        height: 98px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .title-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 110px;
      }
    }
  }
  .issues-main-colum {
    .head {
      height: 85px;
      background-color: $background-color_3;
      border-radius: 5px 5px 0 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .footer {
      height: 30px;
      padding: 10px 0 0 0px;
      background-color: $background-color_3;
      border-radius: 0 0 5px 5px;
      margin-top: 59px;
    }
    h3 {
      text-transform: uppercase;
      color: $subtext-color;
      font-size: 10px;
      text-align: center;
      font-weight: 600;
    }
    .body {
      .title-1 {
        border-bottom: 2px solid $background-color_5;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .title-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100px;
      }
    }
  }
}
.title-1 {
  > h3 {
    font-size: 8px;
    margin-top: -50px;
  }
}
.issues-title-1 {
  > h3 {
    margin-top: -50px;
  }
}
.title-2 {
  > h3 {
    font-size: 8px;
    margin-top: 50px;
  }
}
.issues-title-2 {
  > h3 {
    margin-top: 50px !important;
  }
}
.column-active {
  // background-color: rgba(227, 43, 42, 0.2) !important;
  // border: 1.12027px solid rgba(255, 0, 0, 0.3);
  > .head {
    // background-color: $background-color_4 !important;
    // opacity: 1 !important;
  }
}

.r2 {
  > .upper {
    border-top: 1.12027px solid #ff6969;
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) -34.78%,
      rgba(217, 217, 217, 0) -10.91%,
      rgba(255, 124, 124, 0.2) 100%
    );
    margin-top: 124px;
  }
  > .lower {
    border-bottom: 2px solid #b86e13;
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) -34.78%,
      rgba(217, 217, 217, 0) -10.91%,
      rgb(255, 182, 125, 0.2) 100%
    );
    margin-top: -100px;
    position: relative;
  }
}
.issues-number-top {
  margin-bottom: 62px !important;
}
.footer-icons {
  margin-left: 92px;
  display: flex;
}
.bar-score-container {
  text-align: center;
  width: 70px;
}
.liveness-icon {
  margin-right: 4px;
}
.bar-score {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid $background-color_5;
  position: absolute;
}
.bar-score-low {
  background: #6c83bb;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1100px;
  }
}
