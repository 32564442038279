@import "../../variables.scss";
.input-containers {
  .weeks {
    display: flex;
    .week {
      border: 1px solid $storm-grey;
      border-radius: 3px;
      color: $storm-grey;
      padding: 0px 10px;
      margin-right: 10px;
    }
    .week-active {
      border: 1px solid $week-btn;
      color: $week-btn;
    }
  }

  .occurance-container {
    .select-theme {
      margin-top: 20px;
    }

    .select-theme p {
      margin: 0 0 5px 0;
      padding: 0;
    }

    .radio-label {
      color: $white-color;
      font-size: 14px;
      font-weight: 400;
      margin-right: 7px;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
    }

    .radio-input {
      margin: 0;
      visibility: hidden;
    }

    .radio-input:checked + span {
      border: 2px solid $toggle-button-color;
    }

    .radio-input:checked + span:after {
      opacity: 1;
    }

    .custom-radio {
      left: -8px;
      top: 3px;
      cursor: pointer;
      width: 17px;
      height: 17px;
      border: 2px solid #9a9a9a;
      border-radius: 50%;
      display: inline-block;
      position: relative;
    }

    /* for inner filled circle */
    .custom-radio::after {
      content: "";
      width: 12px;
      height: 12px;
      background: $toggle-button-color;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
}
.delete-icon {
  color: $secondary-btn-hover;
}
.email-chip {
  height: 28px;
  border: 1px solid $spun-pearl;
  border-radius: 5px;
  padding: 0 10px;
  margin-right: 10px;
  margin-top: 8px;
}
.email-input-box {
  border: 0px solid $card-border-color-4;
  align-items: center;
  font-size: 14px;
  background: $input-background !important;
  border-radius: 3px !important;
  color: $secondary-btn-hover !important;
  font-weight: 500;
  width: 100%;
  display: block;
  padding-left: 0.75rem;
}
.email-input:focus {
  outline: 0 !important;
}

.width-full {
  width: 100%;
}
