@import "../../../variables.scss";
#impacted-datastore-modal {
  .modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    .modal-dialog {
      max-width: 65%;
      top: 0px;
      .modal-content {
        height: 100% !important;
        min-height: 250px;
        // max-height: 600px;
        background: $card-background-color;
        .modal-loading-img {
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          text-align: center;
          z-index: 1;
          width: 200px;
          top: 2rem;
        }
        .modal-header {
          background-color: $sub-heading-color;

          padding: 15px 15px;
          .header-title {
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $white-color;
          }
          .impacted-datastore-modal-title {
            &-accept {
              color: $risk-title-accept;
            }
            &-accepted {
              color: $success-color;
            }
          }

          .delete-icon {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
        }
        .modal-body {
          .body-title {
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $sub-heading-color;
            padding-top: 2px;
          }
          .attack-path-section {
            background-color: $ticket-tbody;
            box-sizing: border-box;
            border: 1px solid $card-border-color;
            border-radius: 5px;
            box-shadow: none;
          }
          .nodata-container {
            min-height: auto;
          }
          .toggle-label {
            color: $sub-heading-color;
            vertical-align: text-top;
          }
          .risk-accept-toggle {
            vertical-align: middle;
            .react-toggle-track {
              background: $toggle-track;
              height: 22px;
            }
            .react-toggle-thumb {
              background-color: $toggle-button-color;
              border: none;
              box-shadow: none;
            }
          }
          .node-title {
            color: $sub-heading-color;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            font-size: 14px;
          }
          .node-description {
            font-size: 12px;
          }
          .risk-accept-toggle.react-toggle--checked {
            .react-toggle-track {
              background: $toggle-track;
            }
            .react-toggle-thumb {
              background-color: $toggle-button-color;
              border: none;
              box-shadow: none;
            }
          }
          .close-icon {
            width: 25px;
            margin-top: -40px;
            margin-right: -10px;
            cursor: pointer;
          }
          .impacted-datastore-body {
            background-color: $white-color;
          }
          .impacted-datastore-modal-description {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }
      }
      .modal-footer {
        .modal-button-cancel {
          background-color: $card-heading-color;
          color: $white-color;
        }
      }
    }
  }
}
