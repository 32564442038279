@import "../../../variables.scss";

.dashboard-stats {
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: $white-color;

    hr {
      opacity: 0.5;
      border-bottom: 0.1rem solid $spun-pearl;
    }
  }

  .store-count-delta-red {
    color: $fire-brick;
  }

  .store-count-delta-green {
    color: $dark-pastel-green;
  }

  .store-count-delta-normal {
    color: $manatee-shade1;
  }

  .normal-delta-arrow {
    fill: $manatee-shade1;
    path {
      stroke: $manatee-shade1;
      fill: $manatee-shade1;
    }
  }

  .highlighted-card {
    background: $highlighted-color-card;
    border-radius: 0.375rem;
    min-height: 12rem;
    margin-bottom: 1.5rem;
    height: calc(100% - 1.5rem);

    .card-contents {
      padding: 1.25rem;
      @extend .title;
      .store-count {
        font-style: normal;
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.438rem;
        color: $white-color;
      }
      .store-count-delta {
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.438rem;
      }
      .stores {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.188rem;
        color: $spun-pearl;
        width: 90px;
      }
      .severity {
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.938rem;
        color: $white-color;
        opacity: 0.5;
      }
    }
  }
  .highlighted-sub-card > div:first-of-type {
    border-right: 1px solid rgba(159, 161, 169, 0.5);
    width: 50%;
  }

  .data-card {
    background: $card-background-color;
    height: calc(100% - 1.5rem);

    min-height: 12rem;
    margin-bottom: 1.5rem;
    border-top-width: 2px;
    border-top-style: solid;
    border-radius: 6.9128px;
    border-image: linear-gradient(
        102.8deg,
        #c241af -2.26%,
        #002bff 77.68%,
        #4377f7 117.73%
      )
      3;

    .card-contents {
      padding: 1.25rem;
      @extend .title;
      hr {
        opacity: 0.15;
      }
      .data-card-header {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.188rem;
        color: $manatee-shade1;
      }
      .data-card-value {
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.188rem;
        color: $white-color;
      }
    }
  }
}
