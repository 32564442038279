@import "../../../variables.scss";
#info-modal {
  font-family: $manrope-regular-font;
  .modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    .modal-dialog {
      // top: 10%;
      .modal-content {
        // height: 100% !important;
        background: $card-background-color;
        height: auto;

        .modal-header {
          .header-title {
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $sub-heading-color;
          }

          .delete-icon {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
        }
        .modal-body {
          height: 500px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $subtext-color;
          line-height: 1.5;
          border: none;
          overflow-y: auto;
          margin: 0px 20px;
          .title-1 {
            font-size: 17px;
            color: $white-color;
            margin-bottom: 1em;
            font-weight: bold;
          }
          .sub-title-1 {
            font-size: 15px;
            color: $white-color;
            margin-bottom: 0.5em;
          }
          .sub-title-2 {
            font-size: 15px;
            color: $white-color;
            margin-bottom: 0.5em;
          }
          .title-2 {
            font-size: 16px;
            font-weight: normal;
            color: $white-color;
            margin-bottom: 0.5em;
          }
          ul,
          li {
            list-style-type: disc;
            font-size: 14px;
          }

          ol {
            li {
              list-style-type: circle;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
