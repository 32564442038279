@import "../../variables.scss";

#details-component-main-container {
  // background-color: $body-background-color;
  .top-bar-toggle-button {
    justify-content: end;
    align-items: flex-end;
    // display: flex;
    cursor: pointer;
    div {
      margin: 10px;
      height: 20px;
      background-color: $white-color;
      width: 20px;
      justify-content: center;
      display: flex;
      align-items: center;
      border: 1px solid $card-border-color;
      border-radius: 5px;
      padding: 5px;
    }
  }
  .page-heading {
    font-size: 1rem;
    font-family: $manrope-bold-font;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: $card-heading-color;
    span {
      font-family: $manrope-bold-font;
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      color: $toggle-button-color;
      text-align: left;
    }
  }
  .top-panel-2 {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(211, 211, 211, 1);
    border-radius: 5px;
  }
  .dropdown-title {
    float: left;
    padding-bottom: 12px;
  }
}
.vulns-table-dropdown {
  height: 33px;
  width: 200px;
  background: inherit;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.content-page {
  .vulns-dropdown {
    display: initial;
    width: 250px;
    height: 30px;
    background: #fff;
  }
}
