@import "../../variables.scss";

.cd-container {
  margin-top: 78px;
  margin-left: 78px;
  padding: 30px;
  background-color: $cyprus;
}
.cd-container-extended {
  margin-top: 78px;
  margin-left: 173px;
  padding: 30px;
  background-color: $cyprus;
}
