@import "../../variables.scss";

.doc-container {
  margin: 0em 10em;
  .doc {
    margin: 0em 2em;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    li {
      color: $light-grey !important;

      b {
        font-weight: bolder;
        opacity: 1 !important;
        color: $white-color;
      }
      .monospace {
        font-family: monospace;
      }
      a {
        text-decoration: underline;
        color: $white-color;
      }
    }

    p {
      color: $light-grey !important;
    }
  }
}
