@import "../../variables.scss";

.checklist-container {
  background: $white-color;

  .card-title-danger {
    color: $error-color;
    font-weight: bold;
    margin-bottom: 10px;
    img {
      width: 25px;
      margin-right: 5px;
    }
  }

  .card-title-primary {
    color: $sub-heading-color;
    font-weight: bold;
    margin-bottom: 10px;
    img {
      width: 25px;
      margin-right: 5px;
    }
  }

  .border-bottom-2 {
    border-bottom: 3px solid #888888;
    div {
      p {
        color: $subtext-color;
        line-height: 1.3;
      }
    }
  }

  .row {
    .bg-grey {
      background: #ececec;
      p {
        line-height: 1.3;
        color: $subtext-color;
      }

      .sky-card {
        background: $sub-heading-color;
        color: $white-color;
        padding: 10px;
        font-size: 13px;
        border-radius: 8px;
        span {
          font-weight: bold;
        }
      }

      .title {
        font-weight: bold;
        color: #797979;
      }

      .contents {
        color: $subtext-color;
        margin-top: -12px;
      }

      .btn-container {
        // text-align: center;
        .btn-red {
          background: $error-color;
          border-radius: 16px;
          padding: 5px 10px;
          color: $white-color;
          margin-top: -30px;
        }
      }

      div {
        div {
          h6 {
            span {
              background: $error-color;
              color: $white-color;
              border-radius: 50%;
              padding: 10px 8px;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .bg-light-grey {
      background: $card-background-color;
    }
    div {
      .card {
        border-radius: 10px;
        .border-red {
          border: 1px solid $error-color;
        }

        .bg-red {
          background: $error-color;
          color: $white-color;
        }

        .bg-warning {
          background: $warning-color !important;
          color: $white-color;
        }

        .bg-white {
          color: $sub-heading-color;

          .span {
            background: $error-color;
            font-size: 100px;
          }
        }
        .card-header {
          font-weight: bold;
          padding: 5px 15px;
          font-size: 13px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11);
        }

        .card-body {
          font-size: 12px;
          padding: 5px 15px;
          line-height: 1.4;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11);
        }
        .card-footer {
          margin-top: 2px;
          color: $error-color;
          font-size: 12px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11);
          font-weight: bold;
        }
      }
    }
  }
}
