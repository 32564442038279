@import "../../variables.scss";

footer {
  background: $blackcurrant;
  width: 88%;
  margin-left: 12%;
  .contents {
    h6 {
      color: $white-color;
      font-size: 12px;
      margin-top: 0.5em;
      a {
        cursor: pointer;
        margin: 0em 0.5em;
      }
    }
    margin: 0.5em 1em;
    display: flex;
    justify-content: space-between;
    .logo {
      width: 50px;
    }
  }
}
