@import "../../variables.scss";
#details-component-main-container {
  // background-color: $body-background-color;
  margin-bottom: 2rem;
  .top-bar-toggle-button {
    justify-content: end;
    align-items: flex-end;
    // display: flex;
    cursor: pointer;
    div {
      height: 20px;
      margin: 15px;
      background-color: $white-color;
      width: 20px;
      justify-content: center;
      display: flex;
      align-items: center;
      border: 1px solid $card-border-color;
      border-radius: 5px;
      padding: 5px;
    }
  }
  .page-heading {
    font-size: 1rem;
    font-family: $manrope-bold-font;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: $card-heading-color;
    span {
      font-family: $manrope-bold-font;
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      color: $toggle-button-color;
      text-align: left;
    }
  }
  .top-panel-2 {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(211, 211, 211, 1);
    border-radius: 5px;
  }
}
.graph {
  background-color: $card-background-color;
  border-radius: 5px;
  // height: 100%;
  width: 100%;
  padding-top: 1.5em;
  // padding-right: 5rem;
  .note {
    color: $sub-heading-color;
    margin-left: 15px;
    text-align: center;
    font-size: 12px;
  }
  button {
    background: rgb(203, 8, 8);
    border-radius: 20px;
    border: 2px solid #fff;
    color: #fff;
    font-weight: bold;
    margin: 0px 10px;
    font-size: 10px;
  }
}
