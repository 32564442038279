@import "../../variables.scss";

.card-wrapper {
  width: 25%;
  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
  .card {
    background: $card-background-color;
  }
}

.card-wrapper-small {
  width: 20%;
  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
  .card {
    background: $card-background-color;
  }
}
