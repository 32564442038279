@import "../../../variables.scss";
#add-account {
  font-family: $manrope-regular-font;
  .modal-loading-img {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    width: 200px;
  }
  .modal {
    .modal-dialog {
      width: 670px;
    }
    .modal-content {
      overflow-y: auto;
      background-color: $card-background-color;
    }
  }
  #exampleModalLong
    > div
    > div
    > div:nth-child(2)
    > form
    > div.modal-body
    > div:nth-child(4)
    > div.col.col-lg-9
    > div
    > div
    > div
    > div {
    background-color: $input-color;
  }
  .modal-header {
    display: initial;
    background-color: $card-background-color;
    // border-bottom-color: $card-border-color;
    border-bottom: 0px;
    padding-bottom: 0px;

    .stepper {
      .step-active {
        border-bottom: 2px solid $toggle-button-color;
      }
      .step {
        border-bottom: 3px solid $toggle-button-color;
        &-disable {
          border-bottom: 3px solid $steeper-border;
        }
      }
      .step-number {
        font-weight: 600;
        font-size: 17px;
        width: 32px;
        height: 32px;
        background-color: $toggle-button-color;
        text-align: center;
        color: $white-color;
        border-radius: 50%;
        &-disable {
          background-color: $midnight-express;
          color: $storm-grey;
          font-weight: 600;
          font-size: 17px;
          width: 32px;
          height: 32px;
          text-align: center;
          border-radius: 50%;
        }
      }
      .step-title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $white-color;
        &-disable {
          color: $storm-grey;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    .header-text {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: white;
    }
    .modal-title {
      color: $sub-heading-color;
      font-family: $manrope-regular-font;
      font-size: 18px;
      height: 70px;
    }
    .modal-subtitle {
      color: $sub-heading-color;
      font-size: 14px !important;
      width: 226px;
      height: 19px;
      font-family: $manrope-regular-font;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .count-circle {
      width: 20px;
      height: 20px;
      background-color: $sub-heading-color;
      border-radius: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $white-color;
      font-weight: 600;
      &-small {
        width: 20px;
        height: 20px;
        background-color: $sub-heading-color;
        border-radius: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $white-color;
        font-weight: 600;
      }
    }
    .modal-text {
      color: $card-heading-color;
      font-family: $manrope-regular-font;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .vl {
      border-left: 1px solid #d9d9d9;
      height: 60px;
    }
  }
  .modal-body {
    // border-bottom: 1px solid;
    // min-height: 400px;
    border-bottom-color: $card-border-color;
    font-family: $manrope-regular-font;
    .modal-input-style {
      border-width: 0px;
      left: 0px;
      top: 0px;
      width: 100%;
      border-radius: 2px !important;
      height: 35px;
      background: inherit;
      background-color: $mainly-blue;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: $dark-mainly-blue;
      border-radius: 0px;
      font-family: $manrope-regular-font;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $white-color;
    }

    .modal-title {
      color: $sub-heading-color;
      height: 70px;
      font-family: $manrope-regular-font;
      font-size: 18px;
    }
    .modal-subtitle {
      color: $sub-heading-color;
      font-size: 14px !important;
    }
    .form-field-name {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $anon;
    }
    .mandatory-icon {
      color: $error-color;
      font-size: 18px;
    }
    .modal-input {
      border-width: 0px;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 35px;
      background: inherit;
      background-color: $input-color;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: $card-border-color;
      border-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: $card-heading-color;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .modal-textarea {
      border-width: 0px;
      left: 0px;
      top: 0px;
      width: 100%;
      background: inherit;
      background-color: $mainly-blue;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-radius: 2px !important;

      border-color: $dark-mainly-blue;
      border-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $white-color;
    }
    .modal-subtext {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $manatee-shade1;
      line-height: 20px;
      display: block;
    }
    .modal-button-simple {
      border-width: 0px;
      left: 0px;
      top: 0px;
      height: 30px;
      width: 100%;
      padding-left: 0px;
      background: inherit;
      border-radius: 5px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: $white-color;
      font-size: 12px;
      font-family: $manrope-regular-font;
      border-width: 0px;
      border: none;
      color: $white-color;
      background-color: $secondary-color;
      &:disabled {
        opacity: 0.3;
      }
      .popout-img {
        position: absolute;
        margin-left: 2px;
        width: 16px;
        height: 16px;
      }
    }
    .modal-button-simple:disabled {
      font-weight: 500;
      opacity: 0.5;
    }
    .hr-row {
      width: 363px;
    }
    .modal-card {
      &-img {
        height: 40px;
        width: 120px;
        margin: 0 auto;
        margin-top: 14px;
        cursor: pointer;
      }
      margin: 5px;
      cursor: pointer;

      margin-right: 20px;
      &-title {
        font-family: $manrope-regular-font;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $sub-heading-color;
      }
      background-color: $onboarding-icons;
      border-radius: 6.9128px;
      border: 1px solid transparent;
      padding: 5px 20px;
    }
    .modal-card:hover {
      border: 1px solid $card-border-color-2;
    }
    .modal-card-selected {
      margin: 5px;
      cursor: pointer;
      border: 1px solid $toggle-button-color;

      margin-right: 20px;
      background-color: $onboarding-icons;
      border-radius: 6.9128px;
      padding: 5px 20px;
    }

    .modal-select-style {
      color: $secondary-btn-hover;
      font-family: $manrope-bold-font;
      box-shadow: none;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      height: 30px;
      border-width: 1px;
      border-style: solid;
      border-radius: 2px !important;

      border-color: $card-border-color;
    }
    .modal-input-style-2 {
      border-width: 0px;
      left: 0px;
      top: 0px;
      width: 100%;
      border-radius: 2px !important;
      height: 30px;
      background: inherit;
      background-color: $mainly-blue;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: $dark-mainly-blue;
      border-radius: 0px;
      color: $white-color;

      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .modal-subtext {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $manatee-shade1;
      font-family: $manrope-regular-font;

      line-height: 20px;
      display: block;
    }
    .modal-verify-card {
      height: auto;
      margin: auto;
    }
    .modal-loading-img {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 1;
      width: 200px;
    }
    .modal-verify-title {
      color: $sub-heading-color;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .modal-verify-subtext {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      font-family: $manrope-regular-font;

      line-height: normal;
      letter-spacing: normal;
      color: $subtext-color;
    }
    .modal-verify-number {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      font-family: $manrope-regular-font;
      word-wrap: break-word;
      line-height: normal;
    }
    .modal-error-title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $error-color;
      font-family: $manrope-regular-font;
    }
    .modal-error-subtext {
      font-size: 14px;
      line-height: 1.2;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      font-family: $manrope-regular-font;
      color: $subtext-color;
      width: 500px;
    }
    .verify-alert-icon {
      color: $error-color;
    }
    input[type="radio"] {
      -ms-transform: scale(1.7); /* IE 9 */
      -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
      transform: scale(1.5);
    }

    .custom-radio-button:after {
      display: block;
      content: "   ";
      position: absolute;
      bottom: 6px;
      background: $radio-button-color;
      height: 1px;
      width: 32px;
      right: -31px;
    }
    .last-custom-radio-button input:after {
      display: none;
    }
    .radio-button-title {
      font-family: $manrope-regular-font;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $subtext-color;
      margin: 1px;
    }
    .radio-button-text {
      font-family: $manrope-regular-font;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $card-heading-color;
    }
    hr {
      margin-top: 10px;
    }
  }
  .modal-footer {
    .row {
      width: 100%;
    }
    .modal-button {
      border-width: 0px;
      // position: absolute;
      left: 0px;
      top: 0px;
      // width: 98px;
      // height: 30px;
      background: inherit;
      border: none;
      padding: 6px 15px;
      font-size: 13px;
      border-radius: 5px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: $white-color;
      background-color: $sub-heading-color;
      &:disabled {
        opacity: 0.3;
      }
    }
    .modal-button-cancel {
      border-width: 0px;
      // position: absolute;
      left: 0px;
      top: 0px;
      // width: 98px;
      // height: 30px;
      background: inherit;
      background-color: $card-heading-color;
      border: none;
      border-radius: 5px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      padding: 6px 15px;
      font-size: 13px;
      font-family: $manrope-regular-font;

      color: $white-color;
    }
    .modal-disable-button {
      height: 30px;
      // margin: 20px 20px 0 23px;
      padding: 6px 15px;
      opacity: 0.3;
      color: white;
      border-radius: 5px;
      font-size: 13px;
      font-family: $manrope-regular-font;
      background-color: $sub-heading-color;
    }
  }
  .btn-secondary {
    background: $royal-curtsy;
  }
  .btn-primary {
    opacity: 1;
  }
}
